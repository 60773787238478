<template>
  <section style="padding-top: 20px">
    <div class="container">
      <div class="box box-primary">
        <div class="box-header">Flow Instances</div>
        <div class="box-body">
          <SeachableTable
            :loading="loading"
            :fields="fields"
            :items="list"
            :commands="commands"
            @command="onCommand"
            @select="onSelect"
          />
          <div style="margin-top: 20px" v-if="!loading">
            <div class="btn btn-default" @click.stop.prevent="onNew">
              new instance
            </div>
          </div>
        </div>
      </div>
      <!-- <pre style="margin-top: 40px">
        {{ accessToken() }}
      </pre> -->
    </div>
  </section>
</template>

<script>
import Auth from "@/services/auth.js";
import SeachableTable from "@/components/searchable-table.vue";

const API = "https://app.nred.me/api/v1";

export default {
  name: "FlowsAdmin",
  components: {
    SeachableTable
  },
  data() {
    return {
      items: [],
      item_state: {},
      loading: false
    };
  },
  computed: {
    fields() {
      return [
        {
          name: "name"
        },
        {
          name: "cpu",
          parser: (item) => {
            var cpu_delta =
              (item?.cpu_stats?.cpu_usage?.total_usage || 0) -
              (item?.precpu_stats?.cpu_usage?.total_usage || 0);
            var system_cpu_delta =
              (item?.cpu_stats?.system_cpu_usage || 0) -
              (item?.precpu_stats?.system_cpu_usage || 0);
            var number_cpus = item?.cpu_stats?.online_cpus || 0;
            return system_cpu_delta > 0
              ? (cpu_delta / system_cpu_delta) * number_cpus * 100.0
              : 0;
          }
        },
        {
          name: "memory",
          parser: (item) => {
            // memory_stats.usage - memory_stats.stats.cache
            return (
              (item?.memory_stats?.usage || 0) -
              (item?.memory_stats?.stats?.cache || 0)
            );
          }
        },
        {
          name: "status",
          title: "uptime",
          parser: (item) => {
            return item?.status || "";
          }
        },
        {
          name: "state"
        }
      ];
    },
    commands() {
      return [
        {
          name: "toggle",
          title: "toggle",
          disabled: (item) => this.item_state[item.name] == "busy",
          icon: (item) => {
            if (this.item_state[item.name] == "busy") {
              return "fa fa-refresh fa-spin";
            } else if (item.state == "running") {
              return "fa fa-stop text-danger";
            } else {
              return "fa fa-play-circle-o text-success";
            }
          }
        },
        {
          name: "remove",
          title: "remove",
          icon: "fa fa-trash"
        }
      ];
    },
    list() {
      return (this.items || []).map((item) => {
        if (item?.state == "running") {
          item.class = "instance-running";
        }
        return item;
      });
    }
  },
  methods: {
    onCommand(ev) {
      if (ev && ev.name) {
        if (ev.name in this && typeof this[ev.name] == "function") {
          this[ev.name](ev);
        }
      }
    },
    toggle(ev) {
      var item = ev.target;
      var action = item?.state == "running" ? "stop" : "start";
      var port = item.name.replace(/\D/g, "");
      this.$set(this.item_state, item.name, "busy");
      this.$http
        .get(
          `${API}/container-nred/${port}/${action}`,
          this.auth.requestOptions()
        )
        .then(
          (resp) => {
            this.$set(this.item_state, item.name, "ready");
            if (resp && resp.body) {
              const ix = this.items.findIndex(
                ({name}) => resp.body.name == name
              );
              if (ix >= 0) {
                this.$set(this.items, ix, resp.body);
              }
              return;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    remove(ev) {
      const item = ev.target;
      var port = item.name.replace(/\D/g, "");
      this.$http
        .get(`${API}/container-nred/${port}/remove`, this.auth.requestOptions())
        .then(
          () => {
            this.items = this.items.filter(({name}) => item.name !== name);
            return;
          },
          (error) => {}
        );
    },
    fetchAll() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$http
          .get(`${API}/container-nred/list`, this.auth.requestOptions())
          .then(
            (resp) => {
              this.loading = false;
              if (resp && resp.body) {
                resolve(resp.body);
                return;
              }
              reject(null);
            },
            (error) => {
              this.loading = false;
              reject(null);
            }
          );
      });
    },
    onNew() {
      this.fetchAll().then((items) => {
        var port =
          (_.max(
            (items || []).map(({name}) => parseInt(name.replace(/\D/g, "")))
          ) || 1879) + 1;
        this.$http
          .get(
            `${API}/container-nred/${port}/create`,
            this.auth.requestOptions()
          )
          .then(
            (resp) => {
              if (resp && resp.body) {
                this.items.filter(({name}) => resp.body.name !== name);
                this.items.push(resp.body);
                return;
              }
            },
            (error) => {}
          );
      });
    },
    accessToken() {
      return this.auth.requestOptions();
    },
    onSelect(item) {
      let port = item.name.replace(/\D/g, "");
      let token = (this?.accessToken()?.headers?.Authorization ?? "").replace(
        /Bearer /,
        ""
      );
      if (!token) return;
      window
        .open(`https://${port}.nred.me/?access_token=${token}`, "_blank")
        .focus();
    }
  },
  created() {
    this.auth = new Auth();
    this.fetchAll().then((items) => (this.items = items));
  }
};
</script>

<style scoped>
::v-deep tr.instance-running > td {
  color: green;
  font-weight: 600;
}
</style>